import { hexToRGB } from './utils';
import { DASHBOARD_CARD_GUTTER, DASHBOARD_CARD_WIDTH, SIDEBAR_WIDTH } from './constants'; // Breakpoint utils
export function getContainerWidth(numberOfCards) {
    if (numberOfCards === void 0) {
        numberOfCards = 1;
    }
    return DASHBOARD_CARD_WIDTH * numberOfCards + "px";
}
export function getBreakpointWidth(numberOfCards, sidebarWidth) {
    if (numberOfCards === void 0) {
        numberOfCards = 1;
    }
    if (sidebarWidth === void 0) {
        sidebarWidth = SIDEBAR_WIDTH;
    }
    return DASHBOARD_CARD_WIDTH * numberOfCards + sidebarWidth + DASHBOARD_CARD_GUTTER + "px";
}
export var breakpoints = [DASHBOARD_CARD_WIDTH + DASHBOARD_CARD_GUTTER * 2 + "px", DASHBOARD_CARD_WIDTH * 2 + DASHBOARD_CARD_GUTTER * 3 + "px", DASHBOARD_CARD_WIDTH * 2 + DASHBOARD_CARD_GUTTER * 3 + SIDEBAR_WIDTH + "px", DASHBOARD_CARD_WIDTH * 3 + DASHBOARD_CARD_GUTTER * 4 + SIDEBAR_WIDTH + "px", DASHBOARD_CARD_WIDTH * 4 + DASHBOARD_CARD_GUTTER * 5 + SIDEBAR_WIDTH + "px", DASHBOARD_CARD_WIDTH * 5 + DASHBOARD_CARD_GUTTER * 6 + SIDEBAR_WIDTH + "px", DASHBOARD_CARD_WIDTH * 6 + DASHBOARD_CARD_GUTTER * 7 + SIDEBAR_WIDTH + "px", DASHBOARD_CARD_WIDTH * 7 + DASHBOARD_CARD_GUTTER * 8 + SIDEBAR_WIDTH + "px", DASHBOARD_CARD_WIDTH * 8 + DASHBOARD_CARD_GUTTER * 9 + SIDEBAR_WIDTH + "px", DASHBOARD_CARD_WIDTH * 9 + DASHBOARD_CARD_GUTTER * 10 + SIDEBAR_WIDTH + "px"];
export var containerWidths = [DASHBOARD_CARD_WIDTH + DASHBOARD_CARD_GUTTER * 2 + "px", DASHBOARD_CARD_WIDTH * 2 + DASHBOARD_CARD_GUTTER * 3 + "px", DASHBOARD_CARD_WIDTH * 2 + DASHBOARD_CARD_GUTTER * 3 + "px", DASHBOARD_CARD_WIDTH * 3 + DASHBOARD_CARD_GUTTER * 4 + "px", DASHBOARD_CARD_WIDTH * 4 + DASHBOARD_CARD_GUTTER * 5 + "px", DASHBOARD_CARD_WIDTH * 5 + DASHBOARD_CARD_GUTTER * 6 + "px", DASHBOARD_CARD_WIDTH * 6 + DASHBOARD_CARD_GUTTER * 7 + "px", DASHBOARD_CARD_WIDTH * 7 + DASHBOARD_CARD_GUTTER * 8 + "px", DASHBOARD_CARD_WIDTH * 8 + DASHBOARD_CARD_GUTTER * 9 + "px", DASHBOARD_CARD_WIDTH * 9 + DASHBOARD_CARD_GUTTER * 10 + "px"]; // Color scheme
export var colors = {
    marvelDark: '#009EEB',
    marvel: '#1FB6FF',
    marvelLight: '#89DCFF',
    marvelExtraLight: '#B7EAFB',
    greenDark: '#44C553',
    green: '#60D956',
    greenLight: '#87DC72',
    greenExtraLight: '#ADEA9E',
    orange: '#FD6F3C',
    orangeLight: '#FF977A',
    orangeExtraLight: '#FFB6A1',
    orangeExtraExtraLight: '#FFCEBD',
    yellowDark: '#A36300',
    yellow: '#FFAE30',
    yellowLight: '#FFC772',
    yellowExtraLight: '#FFD79B',
    yellowExtraExtraLight: '#FFE3B7',
    purple: '#AD6EDD',
    purpleLight: '#C896EA',
    purpleExtraLight: '#D6AFF1',
    purpleExtraExtraLight: '#DFC7F0',
    teal: '#60D2C9',
    tealLight: '#8FDFDA',
    tealExtraLight: '#BBEDED',
    tealExtraExtraLight: '#DDF6F6',
    licorice: '#0C1014',
    black: '#222D39',
    steel: '#2A3440',
    slate: '#3E4854',
    silver: '#8792A1',
    smokeExtraDark: '#AAB5C5',
    smokeDark: '#C3CCD7',
    smoke: '#D5DCE3',
    snowExtraDark: '#E6E9EF',
    snowDark: '#F0F2F6',
    snow: '#F9FAFB',
    white: '#FFFFFF',
    red: '#F95849',
    redDark: '#E65143',
    twitter: '#55acee',
    facebook: '#3b5999',
    dribbble: '#ea4c89',
    dropbox: '#007ee5',
    google: '#dc4e41',
    instagram: '#3f729b',
    linkedin: '#0077b5',
    pocket: '#ef4056',
    github: '#333',
    youtube: '#FF0000'
}; // Font families
// TODO: remove the !important when we ditch the people-ui uglyass old CSS
export var fonts = ['"Graphik", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important', '"SF Mono", "Monaco", "Inconsolata", "Fira Mono", "Droid Sans Mono", "Source Code Pro", monospace']; // Radii, spacing scale and typography scale (in px)
export var radii = [3, 4, 6, 8, 10];
export var space = [0, 4, 8, 16, 24, 32, 48, 64, 128];
export var fontSizes = [10, 11, 13, 15, 17, 19, 25, 36, 46, 60]; // Box shadows
var black0006 = hexToRGB(colors.black, 0.006);
var black002 = hexToRGB(colors.black, 0.02);
var black003 = hexToRGB(colors.black, 0.03);
var black005 = hexToRGB(colors.black, 0.05);
var black008 = hexToRGB(colors.black, 0.08);
var black01 = hexToRGB(colors.black, 0.1);
var black012 = hexToRGB(colors.black, 0.12);
var black018 = hexToRGB(colors.black, 0.18);
var black02 = hexToRGB(colors.black, 0.2);
var black03 = hexToRGB(colors.black, 0.3);
var licorice008 = hexToRGB(colors.licorice, 0.08);
var licorice01 = hexToRGB(colors.licorice, 0.1);
var white04 = hexToRGB(colors.white, 0.4);
var white05 = hexToRGB(colors.white, 0.5);
var smokeDark065 = hexToRGB(colors.smokeDark, 0.65);
export var shadows = {
    actionBar: "0 0 1px 0 " + black03 + ", 0 -2px 6px 0 " + black005,
    projectBar: "0 1px 0 0 " + black012 + ", 0 1px 3px 0 " + black012,
    sidebarTabs: "0 1px 0 0 " + smokeDark065,
    sidebarFooter: "0 -1px 0 0 " + smokeDark065,
    borders: {
        top: "0 -1px 0 0 " + black018,
        right: "1px 0 0 0 " + black018,
        bottom: "0 1px 0 0 " + black018,
        left: "-1px 0 0 0 " + black018,
        inset: "inset 0 0 0 1px " + colors.smoke,
        insetLight: "inset 0 0 0 1px " + colors.snowExtraDark
    },
    button: {
        disabled: "inset 1px 0 0 " + colors.smoke + ", inset -1px 0 0 " + colors.smoke + ", inset 0 1px 0 " + colors.smoke + ", inset 0 -1px 0 " + colors.smokeDark + ", 0 1px 2px 0 rgba(12,16,20,0.10)",
        ghost: "inset 1px 0 0 " + colors.smoke + ", inset -1px 0 0 " + colors.smoke + ", inset 0 1px 0 " + colors.smoke + ", inset 0 -1px 0 " + colors.smokeDark + ", 0 1px 2px 0 rgba(12,16,20,0.10)",
        ghostHover: "inset 1px 0 0 " + colors.smoke + ", inset -1px 0 0 " + colors.smoke + ", inset 0 1px 0 " + colors.smoke + ", inset 0 -1px 0 " + colors.smokeDark + ", inset 0 0 0 1px " + colors.smoke + ", 0 2px 4px 0 rgba(12,16,20,0.10)",
        ghostReversed: "inset 0 0 0 1px " + white04,
        ghostReversedHover: "inset 0 0 0 1px rgba(255,255,255,0)",
        withShadow: "0 0 0 1px " + licorice008 + ", 0 1px 3px 0 " + licorice008,
        toolbar: "0 0 0 1px " + black008,
        green: "inset 1px 0 0 " + black002 + ", inset -1px 0 0 " + black002 + ", inset 0 1px 0 " + black002 + ", inset 0 -1px 0 " + black003 + ", inset 0 0 0 1px " + black002 + ", 0 1px 2px 0 rgba(25,64,22,0.10)",
        greenHover: "inset 1px 0 0 " + black002 + ", inset -1px 0 0 " + black002 + ", inset 0 1px 0 " + black002 + ", inset 0 -1px 0 " + black003 + ", inset 0 0 0 1px " + black002 + ", 0 2px 4px 0 rgba(25,64,22,0.10)",
        marvel: "inset 1px 0 0 " + black002 + ", inset -1px 0 0 " + black002 + ", inset 0 1px 0 " + black002 + ", inset 0 -1px 0 " + black003 + ", inset 0 0 0 1px " + black002 + ", 0 1px 2px 0 rgba(2,47,69,0.10)",
        marvelHover: "inset 1px 0 0 " + black002 + ", inset -1px 0 0 " + black002 + ", inset 0 1px 0 " + black002 + ", inset 0 -1px 0 " + black003 + ", inset 0 0 0 1px " + black002 + ", 0 2px 4px 0 rgba(2,47,69,0.10)",
        danger: "inset 1px 0 0 " + black002 + ", inset -1px 0 0 " + black002 + ", inset 0 1px 0 " + black002 + ", inset 0 -1px 0 " + black003 + ", inset 0 0 0 1px " + black002 + ", 0 1px 2px 0 rgba(53,17,13,0.10)",
        dangerHover: "inset 1px 0 0 " + black002 + ", inset -1px 0 0 " + black002 + ", inset 0 1px 0 " + black002 + ", inset 0 -1px 0 " + black003 + ", inset 0 0 0 1px " + black002 + ", 0 2px 4px 0 rgba(81,25,19,0.10)",
        text: '0 1px 0 rgba(12,16,20,0.05)'
    },
    select: {
        disabled: "inset 0 0 0 1px " + colors.smoke,
        ghost: "inset 0 0 0 1px " + colors.smoke,
        ghostFocus: "inset 0 0 0 1px " + colors.marvel,
        ghostHover: "inset 0 0 0 1px " + colors.smokeExtraDark,
        error: "inset 0 0 0 1px " + colors.red
    },
    input: {
        default: "inset 0 0 0 1px " + colors.smoke,
        defaultTransparent: "0 0 0 1px " + black018,
        defaultHover: "inset 0 0 0 1px " + colors.smokeExtraDark,
        defaultFocus: "inset 0 0 0 1px " + colors.marvel,
        whiteTransparentHover: "inset 0 0 0 1px " + white05,
        whiteTransparentFocus: "inset 0 0 0 1px " + colors.white,
        error: "inset 0 0 0 1px " + colors.red,
        disabled: "inset 0 0 0 1px " + colors.smoke,
        underline: "inset 0 -1px 0 " + colors.smoke,
        underlineHover: "inset 0 -1px 0 " + colors.smokeExtraDark,
        underlineFocus: "inset 0 -1px 0 " + colors.marvel
    },
    projectFrame: {
        default: "0 0 0 1px " + black01 + ", 0 1px 2px 0 " + black008 + ";",
        hover: "0 0 0 1px " + black0006 + ", 0 2px 4px 0 " + black003 + ", 0 8px 16px 0 " + black01 + ";",
        active: "0 0 1px 0 " + black02 + ", 0 1px 2px 0 " + black008
    },
    popover: {
        default: "0 0 1px 0 " + black03 + ", 0 2px 6px 0 " + black01,
        raised: "0 0 0 1px " + black008 + ", 0 8px 10px 0 " + black008 + ", 0 8px 24px 0 " + black005
    },
    checkbox: {
        default: "inset 0 0 0 1px " + colors.smoke,
        defaultChecked: "inset 0 0 0 50% " + colors.green
    },
    card: {
        default: "0 0 0 1px " + black012 + ", 0 1px 2px 0 " + black012,
        defaultDarker: "0 0 0 1px " + black018 + ", 0 1px 2px 0 " + black018,
        selected: "0 0 0 2px " + colors.white + ", 0 0 0 4px " + colors.green,
        raised: '0 14px 30px 0 rgba(34, 45, 57, 0.18), 0 4px 3px 0 rgba(34, 45, 57, 0.06), 0 0 0 1px rgba(34, 45, 57, 0.06)'
    },
    image: {
        default: "0 0 0 1px " + black012,
        selected: "0 0 0 2px " + colors.white + ", 0 0 0 4px " + colors.green
    },
    modal: "0 6px 12px 0 " + licorice008,
    deleteProjects: "inset 0 0 0 1px " + black018,
    raiseOnHover: "0 0 0 1px " + black0006 + ", 0 2px 4px 0 " + black003 + ", 0 8px 16px 0 " + black01,
    toast: "0 2px 6px 0 " + licorice01 + ", 0 3px 15px 0 " + licorice008,
    toggleButton: {
        default: "inset 0 0 0 1px " + colors.smoke,
        selected: "inset 0 0 0 2px " + colors.marvel
    },
    userTestType: {
        default: "inset 0 0 0 1px " + colors.smoke,
        selected: "inset 0 0 0 2px " + colors.marvel
    },
    userTestScreenSelector: {
        default: "inset 0 0 0 1px " + colors.smoke,
        error: "inset 0 0 0 2px " + colors.marvel
    },
    sidebarOverlay: {
        left: '8px 0 24px 0 rgba(34,45,57,0.05), 8px 0 10px 0 rgba(34,45,57,0.08)',
        right: '-8px 0 24px 0 rgba(34,45,57,0.05), -8px 0 10px 0 rgba(34,45,57,0.08)'
    },
    userTestTab: {
        selected: "inset 0 -3px 0 " + colors.marvel
    },
    userTestStatsCard: "0px 2px 4px " + colors.black,
    cardIcon: {
        default: '0 0 1px 0 rgba(30, 45, 62, 0.2), 0 1px 3px 0 rgba(30, 45, 62, 0.12)'
    },
    constraintPreview: "0 1px 1px 0 " + colors.smokeDark,
    currentPlan: "0 0 0 2px " + colors.smokeExtraDark
};
export var zIndices = {
    negative: -1,
    belowSidebar: 90,
    sectionHeader: 100,
    popover: 100,
    createProjectPopover: 110,
    sidebar: 120,
    helpBubble: 200,
    navigationBar: 300,
    actionBar: 300,
    windows: 400,
    modal: 900,
    tooltip: 900,
    devToolBar: 999,
    designTool: {
        closeButton: 10,
        toolbar: 200,
        context: 300,
        windows: 400
    },
    projectViewModal: 1001
}; // Custom easing functions
export var easings = {
    bouncy: {
        string: 'cubic-bezier(0.68, -0.55, 0.265, 1.55)',
        array: [0.68, -0.55, 0.265, 1.55]
    },
    smooth: {
        string: 'cubic-bezier(0.23, 1, 0.32, 1)',
        array: [0.23, 1, 0.32, 1]
    }
}; // Custom transitions
export var transitions = {
    smooth: {
        fast: "all 300ms " + easings.smooth.string,
        medium: "all 600ms " + easings.smooth.string,
        slow: "all 900ms " + easings.smooth.string
    }
}; // Theme
var theme = {
    breakpoints: breakpoints,
    colors: colors,
    easings: easings,
    fonts: fonts,
    fontSizes: fontSizes,
    radii: radii,
    shadows: shadows,
    space: space,
    transitions: transitions,
    zIndices: zIndices
};
export * from './constants';
export * from './icons';
export * from './utils';
export default theme;
