/* TODO: As I don't want to add @marvelapp/ui as a dep, but I need to use this
         util, I'm hardcoding it for now until we have marvel-core */
export function hexToRGB(hex, alpha) {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    if (alpha) {
        return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    }
    return `rgb(${r}, ${g}, ${b})`;
}
export function rgbToHex(rgb) {
    const [
    // eslint-disable-next-line no-unused-vars
    _, r, g, b,] = /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/.exec(rgb);
    return `#${[r, g, b]
        .map((c) => {
        const hex = parseInt(c).toString(16);
        return hex.length === 1 ? `0${hex}` : hex;
    })
        .join('')}`.toUpperCase();
}
