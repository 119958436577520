import { createGlobalStyle } from 'styled-components';
import { color, typography } from 'styled-system';

export const GlobalStyle = createGlobalStyle`
  button, html input[type="button"], input[type="reset"], input[type="submit"] {
    line-height: 1 !important;
  }

  html, body {
    padding: 0;
    margin: 0;
  }
  
  body {
    ${color};
    ${typography};
    letter-spacing: -0.2px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  a, a:link, a:visited {
    text-decoration: none;
  }
`;
